@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    /* color: rgb(215, 215, 215);
    background-color: rgb(60, 60, 193); */
    /* background-image: url('./assets/bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
}

